.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.tooltip-with-icon-and-label {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;;
	justify-content: flex-start;
	align-content: stretch;
	align-items: center;

	svg {
		color: #54af17;
	}

	span {
		padding-left: 5px;
		font-weight: 600;
	}
}

.app {
	.ag-theme-one {
		.ag-row-odd {
			background-color: transparent;
		}
		.ag-row-hover {
			background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
		}
	}
}
