.vdl-modal-close {
	right: 15px;
}

.vdl-modal-title {
	width: 92%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-content: stretch;
	align-items: flex-start;
}

.vdl-slide-in-body {
	color: #000;
}

.vdl-modal-body::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

.vdl-modal-body::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0,0,0,.5);
	-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.vdl-button:focus {
	outline: 0;
}

.vdl-modal-body {
	background: #f5f5f5;
}
