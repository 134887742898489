.toast-list {
  z-index: 10000;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
}

.toast-list-item {
  min-width: 40vw;
  max-width: 96vw;
}

@media (max-width: 1024px) {
  .toast-list {
    width: 100%;
  }
}