a {
	&:focus {
		outline-color: -webkit-focus-ring-color; // stylelint-disable-line plugin/no-unsupported-browser-features
		outline-style: auto; // stylelint-disable-line plugin/no-unsupported-browser-features
		outline-width: medium; // stylelint-disable-line plugin/no-unsupported-browser-features
	}
}

body {
	height: auto; // Override adp-css-framework.
}

button {
	background-color: inherit;
	border: 0;
	font-weight: inherit;
	padding: 0;
	text-transform: inherit;
}

.vdl-button {
	&:focus {
		outline-color: -webkit-focus-ring-color; // stylelint-disable-line plugin/no-unsupported-browser-features
		outline-style: auto; // stylelint-disable-line plugin/no-unsupported-browser-features
		outline-width: medium; // stylelint-disable-line plugin/no-unsupported-browser-features
	}
}

select {
	-webkit-appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
	-moz-appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
	-ms-appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
	appearance: none; // stylelint-disable-line plugin/no-unsupported-browser-features
}

.vdl-sidebar-item {
	border: none;
}
