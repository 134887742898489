@-webkit-keyframes slide {
	100% { right: 30px; }
}

@keyframes slide {
	100% { right: 30px; }
}

.session-indicator {
	&__alert {
			position: fixed;
			bottom: 0;
			right: -400px;
			width: 300px;
			&.show {
					-webkit-animation: slide 0.5s forwards;
					-webkit-animation-delay: 2s;
					animation: slide 0.5s forwards;
					animation-delay: 2s;
			}
			.vdl-alert {
					&__close {
							svg {
									fill: #940a0a;
							}
					}
			}
	}
}

.mkpl-modal.session-modal .vdl-modal-dialog.lg .vdl-modal-content .vdl-modal-header .vdl-modal-close {
	display:none;
}

.session-instructions-list {
	margin-top: 10px;
	margin-bottom: -15px;
}

.session-container {
	padding-top: 20px;
}
